import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout title="writing">
    <h2>Notes on Copywriting and Content Strategy for the web.</h2>
    <p>I'm not a writer. But I play one on the web.</p>
  </Layout>
)

